import NavBar from 'src/ui-kit/navBar'

export type SiteLayoutType = {
  children?: JSX.Element
}

export const SiteLayout = (props: SiteLayoutType): JSX.Element => {
  const { children } = props
  return (
    <>
      <NavBar />
      {children}
    </>
  )
}

export const getLayout = (page: JSX.Element) => <SiteLayout>{page}</SiteLayout>

SiteLayout.getLayout = getLayout

export default SiteLayout
