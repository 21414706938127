import styled from 'styled-components'
import Link from 'next/link'
import { AppBar as UnStyledAppBar, Toolbar } from '@material-ui/core'
import theme from 'src/theme'
import Image from 'next/image'

const AppBar = styled(UnStyledAppBar)`
  background-color: ${theme.colors.common.white};
  box-shadow: none;
  color: ${theme.colors.common.black};
`

const LogoWrapper = styled.div`
  flex-grow: 1;
`

const NavBar = () => {
  return (
    <>
      <AppBar position='static'>
        <Toolbar>
          <Link href='https://voiscooters.com'>
            <LogoWrapper data-testid='nav'>
              <Image
                src='/img-voi_brand_logo.svg'
                width={86}
                height={36}
                alt='menu'
              />
            </LogoWrapper>
          </Link>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default NavBar
