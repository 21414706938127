import '../styles/globals.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import 'sentry.server.config'
import 'react-datepicker/dist/react-datepicker.css'
import '../styles/dateTimeInput.css'
import { appWithTranslation } from 'next-i18next'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { StylesProvider } from '@material-ui/core/styles'
import theme from 'src/theme'
import SiteLayout from 'src/layouts/SiteLayout'

// share these global styles across all the pages
const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5% // will make writing 16px as 1.6rem.. 
  }
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 1.6rem; // or the base which is 16px;
  }
`

/*
* could use it like this in the future:
* const MyComponent = () => {
  const themeContext = useContext(ThemeContext); ThemeContext is from 'styled-components'
  ...
  * read more on https://styled-components.com/docs/advanced
}
* */

function App({ Component, pageProps }: AppProps) {
  const getLayout =
    /*
     * NextJS re-renders the entire UI everytime we click a link.
     * This becomes a problem when we actually want to have a persistent layout and only
     * having to re-render certain parts of the screen if needed.
     * For example, in a traditionally SSR app, we might have a
     * <Layout>
     *  <ComponentWithSearch>
     *  <ComponentsWithSubRoutes>
     * </Layout>
     * If we re-render the UI, for example, going to a subroute of ComponentWithSubRoutes, we will lose the
     * state of the ComponentWithSearch because <Layout> will be unmounted and remounted again.
     * This is why we cannot hardcode the <Layout> in our app.
     *
     * Instead, we could get away with each parent component
     * exposing their own `getLayout` method which ideally should return the JSX for their component.
     *
     * As of writing this, we're on Next 11 and they dont have the typings for this.
     * Hence, I'm adding a ts-ignore.
     *
     * Also adding a fallback Layout if no other layouts have been provided.
     * This should be enough for us to create another page for example an account dashboard
     * which should have its own layouts and sub-layouts in sub-components (imagine tabs)
     * */
    /* @ts-ignore */
    Component.getLayout || ((page: JSX.Element) => <SiteLayout>{page}</SiteLayout>)

  return (
    <>
      <Head>
        <script defer data-domain='report.voi.com' src='https://plausible.io/js/script.js'></script>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=0'
        />
      </Head>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          {getLayout(
            <>
              <Component {...pageProps} />
            </>,
          )}
        </StylesProvider>
      </ThemeProvider>
    </>
  )
}
export default appWithTranslation(App)
