const theme = {
  colors: {
    primary: '#F46C63',
    common: {
      black: '#231F20',
      white: '#FFFFFF',
      white2: 'rgb(242, 242, 242)',
      white3: '#FAFAFA',
      white4: '#E3E3E3',
    },
  },
  fontFamily: {
    primary: 'Montserrat',
    secondary: 'Overpass Mono',
  },
}
/*
 * TODO: for some reason exporting the DefaultTheme by merging with the typeof theme and redeclaring
 *  the styled-components module doesn't work right now since typescript has a beef with me since
 *  the past 97 years.
 *  Must defeat typescript and add intellisense support for theming by writing ${props => props.theme.intelli.sense}
 * */

export default theme
